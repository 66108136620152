<template>
  <Teleport to="#app">
    <UiModal
      :title="$t('week_view_question_ask_add_symptom')"
      close-button-name="Close"
      :is-auto-height="true"
      :width="'100%'"
      :max-width="'600px'"
      @close="close"
    >
      <div class="frm">
        <div class="search">
          <div class="inpvl">
            <GeneralSearchInput 
              @search="chooseKeyword"
              :val="keyword"
              :cl="''"
              :placeholder="$t('week_view_question_ask_add_symptom')"
            />
          </div>
        </div>
        <div class="list">
          <template v-for="item in list">
            <div class="item" @click="chooseItem(item)">
              {{ item.trkey ? t(item.trkey) : '' }}
            </div>
            <template v-for="(itemChild, key) in item.childs">
              <div class="item child" @click="chooseItemChild(item, itemChild)">
                {{ itemChild.trkey ? t(itemChild.trkey) : '' }}
              </div>
            </template>
          </template>
        </div>
      </div>
    </UiModal>
  </Teleport>
</template>

<script setup lang="ts">


import type { QuestionSymptom, QuestionSymptomFilterGroup, QuestionSymptomFilter  } from '@/types/question'
import { QuestionSymptoms  } from '@/types/question'

 
const props = defineProps<{ showed: boolean }>()
const emits = defineEmits<{
  (e: 'close'): void
  (e: 'choose', value: QuestionSymptom): void
}>()

const keyword = ref('')
const category = ref<string | null>(null)
const list = ref<QuestionSymptomFilterGroup>({})
const { t } = useI18n()


function close() {
  clear()
  emits('close')
}

function clear() {
  keyword.value = ''
  category.value = null
}

function chooseItemChild(item: QuestionSymptomFilter, itemChild: QuestionSymptomFilter) {
  const selectedSymptom: QuestionSymptom = {
    id: itemChild.id.replace('symptom_', ''),
    type: item.id.replace('symptom_top_', '')
  }
  emits('choose', selectedSymptom)
  clear()
}

function chooseItem(item: QuestionSymptomFilter) {
  category.value = item.id
  loadResults()
}

function chooseKeyword(value: string) {
  keyword.value = value
  loadResults()
}

function categoryFilter(original: QuestionSymptomFilterGroup) {
  const result: QuestionSymptomFilterGroup = {}
  for (const i in original) {
    if (i === category.value) {
      result[i] = JSON.parse(JSON.stringify(original[i]));
    }
  }
  return result
}

function keywordFilter(original: QuestionSymptomFilterGroup) {
  const result: QuestionSymptomFilterGroup = {}
  for (const i in original) {
    const item = original[i]
    for (const c in item.childs) {
      const child = item.childs[c]
      if (t(child.trkey).toLowerCase().includes(keyword.value.toLowerCase())) {
        if (!result[i]) {
          result[i] = JSON.parse(JSON.stringify(item));
          result[i].childs = {}
        }
        result[i].childs[c] = child
      }
    }
  }
  return result
}

function loadResults() {
  list.value = JSON.parse(JSON.stringify(QuestionSymptoms))
  if (keyword.value.trim()) {
    list.value = keywordFilter(list.value)
  }
  if (category.value) {
    list.value = categoryFilter(list.value)
  }
}

onMounted(() => {
  loadResults()
})

watch(() => props.showed, value => {
  if (value) {
    loadResults()
  }
})
</script>



<style scoped>
.modal_sel {
  background-color: white;
  vertical-align: middle;
  width: 100%;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 300px;
  height: 410px;
  border-radius: 5px;
  padding: 1rem;
  display: none;
  border: 1px #dededf solid;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 7%);
}
.modal_sel.showed {
  display: block;
}
.frm {
  height: 100%;
}
.frm .search {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.frm .search .logo_selected {
  width: 25px;
  margin-right: 10px;
  height: 25px;
  border: 0;
}
.frm .search .inpvl {
  width: calc(100% - 30px);
}
.frm .search .inpvl input {
  width: 100%;
}
.frm .list {
  overflow-y: auto;
  max-height: 340px;
}
.frm .list .item {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.frm .list .item.child {
  padding-left: 10px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .frm .list {
    max-height: calc(100% - 70px);
  }
}
</style>