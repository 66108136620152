<template>

  <div class="symptom">    
    <span class="name">
      {{ QuestionSymptoms['symptom_top_' + props.item.type] ? $t(QuestionSymptoms['symptom_top_' + props.item.type].trkey) : '' }}. 
      {{ QuestionSymptoms['symptom_top_' + props.item.type] ? $t(QuestionSymptoms['symptom_top_' + props.item.type].childs['symptom_' + props.item.id].trkey) : '' }}
    </span>
    <i class="icon-remove" @click="handleRemove"></i>
  </div> 
  
</template>


<script setup lang="ts">

import type { QuestionSymptom  } from '@/types/question'
import { QuestionSymptoms  } from '@/types/question'

interface Props {
  item: QuestionSymptom
}

const props = defineProps<Props>()

const emits = defineEmits(['remove'])

const handleRemove = () => {
  emits('remove')
}

</script>


<style scoped>
   
 
.select_symptom .symptom{
  padding: 10px;
  background-color: #dededf;
  border-radius: 30px;
  padding: 10px 13px;
  background-color: #ffffff;
  border-radius: 53px;
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #4c4c4c;
  margin-right: 2px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;

}
.select_symptom .symptom .name{margin-right: 5px;}
.select_symptom .symptom .icon{cursor: pointer;}
.select_symptom .symptom.disable{
  pointer-events: none;
  opacity: 0.5;
}


.icon-remove{
  color: var(--gd-text-color-red);
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px 0;
}
</style>