
import type { 
  QuestionSymptomFilterGroup,
} from './Question.interface'





export const QuestionSymptoms: QuestionSymptomFilterGroup = {

  symptom_top_1: {
    id: "symptom_top_1",
    trkey: "universal_plant_symptoms_type_1",

    childs: {
      symptom_1: {
        id: "symptom_1",
        trkey: "universal_plant_symptom_1",
        full_trkey: "universal_plant_symptoms_type_1. Not fattening"
      },
      symptom_2: {
        id: "symptom_2",
        trkey: "universal_plant_symptom_2",
        full_trkey: "universal_plant_symptoms_type_1. Too few"
      },
      symptom_3: {
        id: "symptom_3",
        trkey: "universal_plant_symptom_3",
        full_trkey: "universal_plant_symptoms_type_1. Other"
      }
    }
  },
  symptom_top_2: {
    id: "symptom_top_2",
    trkey: "universal_plant_symptoms_type_2",

    childs: {
      symptom_24: {
        id: "symptom_24",
        trkey: "universal_plant_symptom_24",
        full_trkey: "universal_plant_symptoms_type_2. Color - Black or grey"
      },
      symptom_23: {
        id: "symptom_23",
        trkey: "universal_plant_symptom_23",
        full_trkey: "universal_plant_symptoms_type_2. Color - Dark-brown"
      },
      symptom_22: {
        id: "symptom_22",
        trkey: "universal_plant_symptom_22",
        full_trkey: "universal_plant_symptoms_type_2. Color - Dark-purple"
      },
      symptom_25: {
        id: "symptom_25",
        trkey: "universal_plant_symptom_25",
        full_trkey: "universal_plant_symptoms_type_2. Color - Mottling"
      },
      symptom_21: {
        id: "symptom_21",
        trkey: "universal_plant_symptom_21",
        full_trkey: "universal_plant_symptoms_type_2. Color - Pale"
      },
      symptom_20: {
        id: "symptom_20",
        trkey: "universal_plant_symptom_20",
        full_trkey: "universal_plant_symptoms_type_2. Color - Red or pink"
      },
      symptom_19: {
        id: "symptom_19",
        trkey: "universal_plant_symptom_19",
        full_trkey: "universal_plant_symptoms_type_2. Color - Yellow"
      },
      symptom_11: {
        id: "symptom_11",
        trkey: "universal_plant_symptom_11",
        full_trkey: "universal_plant_symptoms_type_2. Curl down"
      },
      symptom_10: {
        id: "symptom_10",
        trkey: "universal_plant_symptom_10",
        full_trkey: "universal_plant_symptoms_type_2. Curl up"
      },
      symptom_12: {
        id: "symptom_12",
        trkey: "universal_plant_symptom_12",
        full_trkey: "universal_plant_symptoms_type_2. Dropping off"
      },
      symptom_6: {
        id: "symptom_6",
        trkey: "universal_plant_symptom_6",
        full_trkey: "universal_plant_symptoms_type_2. Edges burnt"
      },
      symptom_7: {
        id: "symptom_7",
        trkey: "universal_plant_symptom_7",
        full_trkey: "universal_plant_symptoms_type_2. Tips - Burnt"
      },
      symptom_8: {
        id: "symptom_8",
        trkey: "universal_plant_symptom_8",
        full_trkey: "universal_plant_symptoms_type_2. Tips - Die"
      },
      symptom_9: {
        id: "symptom_9",
        trkey: "universal_plant_symptom_9",
        full_trkey: "universal_plant_symptoms_type_2. Tips - Thick"
      },
      symptom_5: {
        id: "symptom_5",
        trkey: "universal_plant_symptom_5",
        full_trkey: "universal_plant_symptoms_type_2. Too few"
      },
      symptom_4: {
        id: "symptom_4",
        trkey: "universal_plant_symptom_4",
        full_trkey: "universal_plant_symptoms_type_2. Too many"
      },
      symptom_13: {
        id: "symptom_13",
        trkey: "universal_plant_symptom_13",
        full_trkey: "universal_plant_symptoms_type_2. Twisted"
      },
      symptom_18: {
        id: "symptom_18",
        trkey: "universal_plant_symptom_18",
        full_trkey: "universal_plant_symptoms_type_2. Veins - stay green"
      },
      symptom_17: {
        id: "symptom_17",
        trkey: "universal_plant_symptom_17",
        full_trkey: "universal_plant_symptoms_type_2. Veins - yellow between"
      },
      symptom_14: {
        id: "symptom_14",
        trkey: "universal_plant_symptom_14",
        full_trkey: "universal_plant_symptoms_type_2. Webbing"
      },
      symptom_16: {
        id: "symptom_16",
        trkey: "universal_plant_symptom_16",
        full_trkey: "universal_plant_symptoms_type_2. White powder"
      },
      symptom_15: {
        id: "symptom_15",
        trkey: "universal_plant_symptom_15",
        full_trkey: "universal_plant_symptoms_type_2. Wilting"
      },
      symptom_26: {
        id: "symptom_26",
        trkey: "universal_plant_symptom_26",
        full_trkey: "universal_plant_symptoms_type_2. Other"
      }
    }
  },
  symptom_top_3: {
    id: "symptom_top_3",
    trkey: "universal_plant_symptoms_type_3",

    childs: {
      symptom_36: {
        id: "symptom_36",
        trkey: "universal_plant_symptom_36",
        full_trkey: "universal_plant_symptoms_type_3. Spaces between nodes"
      },
      symptom_40: {
        id: "symptom_40",
        trkey: "universal_plant_symptom_40",
        full_trkey: "universal_plant_symptoms_type_3. Stem - Red or purple"
      },
      symptom_39: {
        id: "symptom_39",
        trkey: "universal_plant_symptom_39",
        full_trkey: "universal_plant_symptoms_type_3. Stem - Weak"
      },
      symptom_34: {
        id: "symptom_34",
        trkey: "universal_plant_symptom_34",
        full_trkey: "universal_plant_symptoms_type_3. Too short"
      },
      symptom_35: {
        id: "symptom_35",
        trkey: "universal_plant_symptom_35",
        full_trkey: "universal_plant_symptoms_type_3. Too tall"
      },
      symptom_37: {
        id: "symptom_37",
        trkey: "universal_plant_symptom_37",
        full_trkey: "universal_plant_symptoms_type_3. Twisted"
      },
      symptom_38: {
        id: "symptom_38",
        trkey: "universal_plant_symptom_38",
        full_trkey: "universal_plant_symptoms_type_3. Wilting"
      },
      symptom_41: {
        id: "symptom_41",
        trkey: "universal_plant_symptom_41",
        full_trkey: "universal_plant_symptoms_type_3. Other"
      }
    }
  },
  symptom_top_4: {
    id: "symptom_top_4",
    trkey: "universal_plant_symptoms_type_4",

    childs: {
      symptom_42: {
        id: "symptom_42",
        trkey: "universal_plant_symptom_42",
        full_trkey: "universal_plant_symptoms_type_4. Color - Brown"
      },
      symptom_43: {
        id: "symptom_43",
        trkey: "universal_plant_symptom_43",
        full_trkey: "universal_plant_symptoms_type_4. Mushy"
      },
      symptom_45: {
        id: "symptom_45",
        trkey: "universal_plant_symptom_45",
        full_trkey: "universal_plant_symptoms_type_4. Smelly"
      },
      symptom_44: {
        id: "symptom_44",
        trkey: "universal_plant_symptom_44",
        full_trkey: "universal_plant_symptoms_type_4. Too few"
      },
      symptom_46: {
        id: "symptom_46",
        trkey: "universal_plant_symptom_46",
        full_trkey: "universal_plant_symptoms_type_4. Other"
      }
    }
  },
  symptom_top_5: {
    id: "symptom_top_5",
    trkey: "universal_plant_symptoms_type_5",

    childs: {
      symptom_50: {
        id: "symptom_50",
        trkey: "universal_plant_symptom_50",
        full_trkey: "universal_plant_symptoms_type_5. Clones"
      },
      symptom_53: {
        id: "symptom_53",
        trkey: "universal_plant_symptom_53",
        full_trkey: "universal_plant_symptoms_type_5. Lighting"
      },
      symptom_56: {
        id: "symptom_56",
        trkey: "universal_plant_symptom_56",
        full_trkey: "universal_plant_symptoms_type_5. Outdoor"
      },
      symptom_55: {
        id: "symptom_55",
        trkey: "universal_plant_symptom_55",
        full_trkey: "universal_plant_symptoms_type_5. Seedling"
      },
      symptom_49: {
        id: "symptom_49",
        trkey: "universal_plant_symptom_49",
        full_trkey: "universal_plant_symptoms_type_5. Seeds"
      },
      symptom_52: {
        id: "symptom_52",
        trkey: "universal_plant_symptom_52",
        full_trkey: "universal_plant_symptoms_type_5. Sensors"
      },
      symptom_48: {
        id: "symptom_48",
        trkey: "universal_plant_symptom_48",
        full_trkey: "universal_plant_symptoms_type_5. Strain - Autoflowering"
      },
      symptom_47: {
        id: "symptom_47",
        trkey: "universal_plant_symptom_47",
        full_trkey: "universal_plant_symptoms_type_5. Strains - Photoperiod"
      },
      symptom_51: {
        id: "symptom_51",
        trkey: "universal_plant_symptom_51",
        full_trkey: "universal_plant_symptoms_type_5. Substrates"
      },
      symptom_54: {
        id: "symptom_54",
        trkey: "universal_plant_symptom_54",
        full_trkey: "universal_plant_symptoms_type_5. Ventilation"
      },
      symptom_57: {
        id: "symptom_57",
        trkey: "universal_plant_symptom_57",
        full_trkey: "universal_plant_symptoms_type_5. Other"
      }
    }
  },
  symptom_top_6: {
    id: "symptom_top_6",
    trkey: "universal_plant_symptoms_type_6",

    childs: {
      symptom_58: {
        id: "symptom_58",
        trkey: "universal_plant_symptom_58",
        full_trkey: "universal_plant_symptoms_type_6. Automatic systems"
      },
      symptom_60: {
        id: "symptom_60",
        trkey: "universal_plant_symptom_60",
        full_trkey: "universal_plant_symptoms_type_6. Chemical composition"
      },
      symptom_61: {
        id: "symptom_61",
        trkey: "universal_plant_symptom_61",
        full_trkey: "universal_plant_symptoms_type_6. Deficiences"
      },
      symptom_59: {
        id: "symptom_59",
        trkey: "universal_plant_symptom_59",
        full_trkey: "universal_plant_symptoms_type_6. Schedule"
      },
      symptom_62: {
        id: "symptom_62",
        trkey: "universal_plant_symptom_62",
        full_trkey: "universal_plant_symptoms_type_6. Other"
      }
    }
  },
  symptom_top_7: {
    id: "symptom_top_7",
    trkey: "universal_plant_symptoms_type_7",

    childs: {
      symptom_65: {
        id: "symptom_65",
        trkey: "universal_plant_symptom_65",
        full_trkey: "universal_plant_symptoms_type_7. 12-12 from seeds"
      },
      symptom_71: {
        id: "symptom_71",
        trkey: "universal_plant_symptom_71",
        full_trkey: "universal_plant_symptoms_type_7. Defoliation"
      },
      symptom_69: {
        id: "symptom_69",
        trkey: "universal_plant_symptom_69",
        full_trkey: "universal_plant_symptoms_type_7. FIMing"
      },
      symptom_64: {
        id: "symptom_64",
        trkey: "universal_plant_symptom_64",
        full_trkey: "universal_plant_symptoms_type_7. HST"
      },
      symptom_63: {
        id: "symptom_63",
        trkey: "universal_plant_symptom_63",
        full_trkey: "universal_plant_symptoms_type_7. LST"
      },
      symptom_70: {
        id: "symptom_70",
        trkey: "universal_plant_symptom_70",
        full_trkey: "universal_plant_symptoms_type_7. Main-lining"
      },
      symptom_67: {
        id: "symptom_67",
        trkey: "universal_plant_symptom_67",
        full_trkey: "universal_plant_symptoms_type_7. ScrOG"
      },
      symptom_66: {
        id: "symptom_66",
        trkey: "universal_plant_symptom_66",
        full_trkey: "universal_plant_symptoms_type_7. SoG"
      },
      symptom_68: {
        id: "symptom_68",
        trkey: "universal_plant_symptom_68",
        full_trkey: "universal_plant_symptoms_type_7. Topping"
      },
      symptom_72: {
        id: "symptom_72",
        trkey: "universal_plant_symptom_72",
        full_trkey: "universal_plant_symptoms_type_7. Other"
      }
    }
  },
  symptom_top_8: {
    id: "symptom_top_8",
    trkey: "universal_plant_symptoms_type_8",

    childs: {
      symptom_27: {
        id: "symptom_27",
        trkey: "universal_plant_symptom_27",
        full_trkey: "universal_plant_symptoms_type_8. Bugs"
      },
      symptom_32: {
        id: "symptom_32",
        trkey: "universal_plant_symptom_32",
        full_trkey: "universal_plant_symptoms_type_8. General questions"
      },
      symptom_30: {
        id: "symptom_30",
        trkey: "universal_plant_symptom_30",
        full_trkey: "universal_plant_symptoms_type_8. Harvest - Curing"
      },
      symptom_29: {
        id: "symptom_29",
        trkey: "universal_plant_symptom_29",
        full_trkey: "universal_plant_symptoms_type_8. Harvest - Drying"
      },
      symptom_31: {
        id: "symptom_31",
        trkey: "universal_plant_symptom_31",
        full_trkey: "universal_plant_symptoms_type_8. Harvest - Smoking"
      },
      symptom_28: {
        id: "symptom_28",
        trkey: "universal_plant_symptom_28",
        full_trkey: "universal_plant_symptoms_type_8. Mold"
      },
      symptom_33: {
        id: "symptom_33",
        trkey: "universal_plant_symptom_33",
        full_trkey: "universal_plant_symptoms_type_8. Other"
      }
    }
  },
}