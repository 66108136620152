<template>

  <div class="warn"> 
    <div class="text">
      <i class="icon-warning-triangle"></i>
      {{ props.message }}
    </div>    
  </div>
  
</template>


<script setup lang="ts">

interface PageProps {
  message: string
}

const props = defineProps<PageProps>()

</script>
 

 
<style scoped>

.warn{    
  background-color: #FFF8DB;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
  
.warn .text{}



</style>